import { axiosClient } from "../utlis/axiosClient";

export const getWebsiteContent = async <T>() => {
  try {
    const { data }: { data: T } = await axiosClient.get(
      `/public/website-content`
    );
    // console.log(data, "== this is content");
    return data;
  } catch (error: any) {
    console.error({ error });

    throw new Error("An error occured. Please try again.");
  }
};
