import Button from "../../../common/Button";
import styles from "./SuperComboBanner.module.scss";
// import SuperComboMeal from "../../../../assests/images/HomePage/super-combo-meal-plan.png";
import { BannerProps } from "../HeroBanner";
import { Oval } from "react-loader-spinner";
import TakeCharge from "../../../../assests/images/HomePage/sofresh-take-charge.webp";
// import SuperComboMeal from '../../../../assests/images/HomePage/super-combo-meal-plan__.webp'

const SuperComboBanner = ({ heroSection, isLoading }: BannerProps) => {
  return (
    <section className={styles.superComboBanner}>
      {isLoading ? (
        <div className="w-full flex items-center justify-center py-[250px]">
          <Oval wrapperClass="ml-2" height={50} width={50} strokeWidth={5} />
        </div>
      ) : (
        <div className={`container ${styles.superComboContainer}`}>
          <article className={styles.superComboArticle}>
            {heroSection?.text ? (
              <h2 className={styles.superComboArticleTitle}>
                {heroSection?.text}
              </h2>
            ) : (
              <h2 className={styles.superComboArticleTitle}>
                Fresh Beginnings, Fresh Blessings This Ramadan!
              </h2>
            )}

            {heroSection?.sub_text ? (
              <p className={styles.superComboArticleText}>
                {heroSection?.sub_text}
              </p>
            ) : (
              <p className={styles.superComboArticleText}>
                Embrace the spirit of Ramadan with nourishing meals that keep
                you refreshed and energized throughout your fast. Our Ramadan
                Specials are thoughtfully crafted to support your well-being,
                making every day wholesome and satisfying.
              </p>
            )}

            {heroSection?.action_text ? (
              <p className={styles.superComboArticleSubText}>
                {heroSection?.action_text}
              </p>
            ) : (
              <p className={styles.superComboArticleSubText}>
                {/* Order now for delivery or pickup */}
                <strong>REFRESH YOUR FAST</strong> with our exclusive offers:
                packed with fresh, natural goodness to fuel your body and uplift
                your soul.
              </p>
            )}

            {/* <p className={styles.superComboArticleText}>
            Thrill your taste buds, while you nourish your body, with our
            incredibly delicious SUPER COMBO OFFERS! Relish the best of fresh,
            Nutrient-rich and healthy Meals in one Offer!
          </p> */}
            {/* <p className={styles.superComboArticleSubText}>
            3 TIMES FRESHER! 3 TIMES HEALTHIER!
          </p> */}

            <div className={styles.btnContainer}>
              <Button
                // linkHref="https://chowdeck.com/store/tag/so-fresh?page=tags"
                linkHref={
                  heroSection?.link
                    ? heroSection?.link
                    : "https://ramadan.sofreshng.com/"
                }
                linkText="Order Now"
              />
              {/* <Button linkHref='/meal-plans' linkText='Order Now' /> */}
            </div>
          </article>

          <figure
            className={`${styles.superComboImageContainer} w-full flex items-center justify-end`}
          >
            <img
              className={styles.superComboImage}
              src={
                !isLoading && heroSection?.image_url
                  ? heroSection?.image_url
                  : TakeCharge
              }
              alt="Super Combo Meal Plans"
              loading="eager"
            />
          </figure>
        </div>
      )}
    </section>
  );
};

export default SuperComboBanner;
